<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学术管理</el-breadcrumb-item>
      <el-breadcrumb-item>裁判系统</el-breadcrumb-item>
      <el-breadcrumb-item>网络总决赛</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>选择考场</span>
        </el-col>
      </el-row>
      <el-form
        ref="queryFormRef"
        :model="queryForm"
        :rules="queryFormRule"
        label-width="80px"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="考场名称" prop="id">
              <el-select
                v-model="queryForm.id"
                @change="matchInfoShow = false"
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in examRoomList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-button type="primary" @click="getMatchInfo()">查 询</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!-- 考场信息 -->
    <el-card style="margin-top: 15px" v-if="matchInfoShow">
      <el-row :gutter="20" class="el-row">
        <el-col :span="5">
          <span class="exam_span_class"
            >考场名称: {{ examRoomInfo.name }}</span
          >
        </el-col>
        <el-col :span="5">
          <span class="exam_span_class"
            >组别: {{ examRoomInfo.groupStr }}</span
          >
        </el-col>
        <el-col :span="5">
          <span class="exam_span_class"
            >会议号: {{ examRoomInfo.meetingNumber }}</span
          >
        </el-col>
        <el-col :span="5">
          <span class="exam_span_class"
            >包含考位数: {{ examRoomInfo.sitnum }}</span
          >
        </el-col>
      </el-row>
      <el-row :gutter="20" class="el-row">
        <el-col :span="5">
          <span class="exam_span_class"
            >已选考位数: {{ examRoomInfo.cursit }}</span
          >
        </el-col>
        <el-col :span="15">
          <span class="exam_span_class"
            >比赛时间: {{ examRoomInfo.startTime }} 至 {{ examRoomInfo.endTime }} </span
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 比赛列表信息 -->
    <el-card class="list_card" style="margin-top: 20px" v-if="matchInfoShow">
      <el-row class="title_row">
        <el-col :span="12">
          <div class="list_title">比赛信息</div>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right">
            <el-button type="primary" @click="addExamineeClick"
              >添加考生</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="examMatchInfo.saoreList"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        :row-class-name="tableRowClassName"
        border
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column
          label="姓名"
          prop="userName"
          width="100px"
        >
        <template slot-scope="scope">
          <span v-if="scope.row.phone === highLightPhone" style="color: red">{{scope.row.userName}}</span>
          <span v-else>{{scope.row.userName}}</span>
          </template></el-table-column>
        <el-table-column
          label="英文名"
          prop="enName"
        ><template slot-scope="scope">
          <span v-if="scope.row.phone === highLightPhone" style="color: red">{{scope.row.enName}}</span>
          <span v-else>{{scope.row.enName}}</span>
          </template></el-table-column>
        <el-table-column
          label="手机号"
          prop="phone"
        >
        <template slot-scope="scope">
          <span v-if="scope.row.phone === highLightPhone" style="color: red">{{scope.row.phone}}</span>
          <span v-else>{{scope.row.phone}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="年级"
          prop="grade"
          width="80px"
        ></el-table-column>
        <el-table-column
          label="座位号"
          prop="sitNum"
          width="80px"
        ></el-table-column>
        <el-table-column
          label="学校"
          prop="schoolName"
          width="220px"
        ></el-table-column>
        <el-table-column label="Round1（5分）" prop="round1">
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.round1"
              :disabled="scope.row.score === -1 || scope.row.score === -2"
              :true-label="1"
              :false-label="0"
              :style="{ '--fill-color': '#67C23A' }"
              @change="onPassClickChange(scope)"
            >
              对
            </el-checkbox>
            <el-checkbox
              v-model="scope.row.round1"
              :disabled="scope.row.score === -1 || scope.row.score === -2"
              :true-label="0"
              :false-label="1"
              @change="onPassClickChange(scope)"
              :style="{ '--fill-color': '#F56C6C' }"
            >
              错
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Round2（8分）" prop="round2">
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.round2"
              :disabled="scope.row.score === -1 || scope.row.score === -2"
              :true-label="1"
              :false-label="0"
              :style="{ '--fill-color': '#67C23A' }"
              @change="onPassClickChange(scope)"
            >
              对
            </el-checkbox>
            <el-checkbox
              v-model="scope.row.round2"
              :disabled="scope.row.score === -1 || scope.row.score === -2"
              :true-label="0"
              :false-label="1"
              @change="onPassClickChange(scope)"
              :style="{ '--fill-color': '#F56C6C' }"
            >
              错
            </el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Round3（10分）" prop="round3">
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.round3"
              :disabled="scope.row.score === -1 || scope.row.score === -2"
              :true-label="1"
              :false-label="0"
              :style="{ '--fill-color': '#67C23A' }"
              @change="onPassClickChange(scope)"
            >
              对
            </el-checkbox>
            <el-checkbox
              v-model="scope.row.round3"
              :disabled="scope.row.score === -1 || scope.row.score === -2"
              :true-label="0"
              :false-label="1"
              @change="onPassClickChange(scope)"
              :style="{ '--fill-color': '#F56C6C' }"
            >
              错
            </el-checkbox>
          </template></el-table-column
        >
        <el-table-column label="Round4（12分）" prop="round4">
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.round4"
              :disabled="scope.row.score === -1 || scope.row.score === -2"
              :true-label="1"
              :false-label="0"
              :style="{ '--fill-color': '#67C23A' }"
              @change="onPassClickChange(scope)"
            >
              对
            </el-checkbox>
            <el-checkbox
              v-model="scope.row.round4"
              :disabled="scope.row.score === -1 || scope.row.score === -2"
              :true-label="0"
              :false-label="1"
              @change="onPassClickChange(scope)"
              :style="{ '--fill-color': '#F56C6C' }"
            >
              错
            </el-checkbox>
          </template></el-table-column
        >
        <el-table-column label="Round5（15分）" prop="round5" v-if="netMatchType === '5'">
          <template slot-scope="scope">
            <el-checkbox
              v-model="scope.row.round5"
              :disabled="scope.row.score === -1 || scope.row.score === -2"
              :true-label="1"
              :false-label="0"
              :style="{ '--fill-color': '#67C23A' }"
              @change="onPassClickChange(scope)"
            >
              对
            </el-checkbox>
            <el-checkbox
              v-model="scope.row.round5"
              :disabled="scope.row.score === -1 || scope.row.score === -2"
              :true-label="0"
              :false-label="1"
              @change="onPassClickChange(scope)"
              :style="{ '--fill-color': '#F56C6C' }"
            >
              错
            </el-checkbox>
          </template></el-table-column
        >
        <el-table-column label="总分" prop="score">
          <template scope="scope">
            <span class="scoreClass" style="color: red" v-if="scope.row.score === -2">弃赛</span>
            <span class="scoreClass" style="color: black" v-else-if="scope.row.score === -1">锁定</span>
            <span class="scoreClass" v-else>{{ scope.row.score }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              @click="showDeleteExamUserDialog(scope.row)"
              >删除</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="lockUserDialog(scope)"
              >
              <i :class="scope.row.score === -1 ? 'iconfont icon-lock' : 'iconfont icon-unlock'"></i>
              {{scope.row.score === -1 ? '解锁' : '锁定'}}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="card-bottom">
        <el-button
          type="primary"
          style="width: 200px"
          @click="examInfoSaveClicked"
          >保存</el-button
        >
      </div>
    </el-card>
    <!-- 添加考生的Dialog -->
    <el-dialog
      title="添加考生"
      :visible.sync="addExamineeDialogVisible"
      width="35%"
      @close="closeAddExamineeDialog()"
    >
      <el-form
        :model="addExamineeForm"
        ref="addExamineeFormRef"
        :rules="addExamineeFormRules"
        label-width="120px"
      >
        <el-form-item label="考生手机号" prop="phones">
          <el-input
            v-model="addExamineeForm.phones"
            placeholder="请输入考生手机号"
            style="width: 80%"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addExamineeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAddExamineeClick"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryExaminationListPage, getExamRoomMatchInfo, getExamRoomDataInfo, batchExaminationRoom, removeUserFromExamRoom, saveNetScores } from '@/http/api'

export default {
  data () {
    return {
      highLightPhone: '',
      isChange: false,
      matchInfoShow: false,
      netMatchType: '0',
      queryForm: {
        pageNum: 1,
        pageSize: 1000,
        id: this.$route.query.roomId,
        raceScheduleId: this.$route.query.raceSchedule
      },
      totalScore: -1,
      examRoomList: [],
      examMatchInfo: {
        raceSchedule: this.$route.query.raceSchedule,
        activityType: this.$chnEngStatusCode.activityType,
        saoreList: []
      },
      queryFormRule: {
        id: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      examRoomInfo: {},
      addExamineeDialogVisible: false,
      addExamineeForm: {
        phones: null
      },
      addExamineeFormRules: {
        phones: [{ required: true, message: '请填写手机号', trigger: 'blur' }]
      },
      deleteExamineeData: {}
    }
  },
  created () {
    this.getExamRoomInfo()
    this.netMatchType = this.$route.query.netMatchType
    console.log('this.netMatchType', this.netMatchType)
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (row.score === -1) {
        return 'warning-ibc-row'
      } else if (row.score === -2) {
        return 'warning-row'
      } else {
        return ''
      }
    },
    // 获取考场列表
    getExamRoomInfo () {
      queryExaminationListPage(this.queryForm).then(res => {
        this.examRoomList = res.data.list
      })
    },
    goBack () {
      this.$router.back()
    },
    // 获取考场信息
    getMatchInfo (flag) {
      this.$refs.queryFormRef.validate(async valid => {
        if (!valid) return
        getExamRoomDataInfo(this.queryForm).then(res => {
          this.examRoomInfo = res.data
          this.examRoomInfo.groupStr = this.$userInfo.UserGroupStrWithGrade(this.examRoomInfo.levelstr)
          this.getGameInfo()
        })
      })
    },
    // 获取考场成员列表信息
    getGameInfo (type) {
      this.$refs.queryFormRef.validate(async valid => {
        if (!valid) return
        getExamRoomMatchInfo(this.queryForm).then(res => {
          this.examMatchInfo.saoreList = res.data
          if (type === 'forfeit') {
            this.saveExamInfoData('forfeit')
          } else {
            if (type === 'staging') {
              this.addExamineeDialogVisible = true
            }
            this.matchInfoShow = true
          }
        })
      })
    },
    // 添加考生点击事件（显示添加考生弹窗）
    addExamineeClick () {
      // if (this.isChange === true) {
      //   this.$confirm('是否先保存当前改动信息?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(async () => {
      //     this.saveExamInfoData('staging')
      //   }).catch(() => {
      //     this.isChange = false
      //     this.getGameInfo('staging')
      //   })
      // } else {
      this.addExamineeDialogVisible = true
      // }
    },
    saveExamInfoData (type) {
      if (type === 'save') {
        console.log('this.examMatchInfo.saoreList', this.examMatchInfo.saoreList)
        for (let index = 0; index < this.examMatchInfo.saoreList.length; index++) {
          const element = this.examMatchInfo.saoreList[index]
          if (element.score !== -1 && element.score !== -2) {
            var message = ''
            if ((!element.round1 && element.round1 !== 0) || element.round1 === -1) {
              message = '请选择"' + element.userName + '"  round1  答题是否正确'
              return this.$message.error(message)
            }
            if ((!element.round2 && element.round2 !== 0) || element.round2 === -1) {
              message = '请选择"' + element.userName + '"  round2  答题是否正确'
              return this.$message.error(message)
            }
            if ((!element.round3 && element.round3 !== 0) || element.round3 === -1) {
              message = '请选择"' + element.userName + '"  round3  答题是否正确'
              return this.$message.error(message)
            }
            if ((!element.round4 && element.round4 !== 0) || element.round4 === -1) {
              message = '请选择"' + element.userName + '"  round4  答题是否正确'
              return this.$message.error(message)
            }
            if (this.netMatchType === '5' && ((!element.round5 && element.round5 !== 0) || element.round5 === -1)) {
              message = '请选择"' + element.userName + '"  round5  答题是否正确'
              return this.$message.error(message)
            }
          } else {
            if (element.score === -1) {
              return this.$confirm(element.userName + '考生暂无成绩', '提示', {
                confirmButtonText: '弃赛',
                cancelButtonText: '未弃赛',
                distinguishCancelAndClose: true,
                type: 'warning'
              }).then(() => {
              // 确认弃赛
                element.score = -2
                var examInfo = this.examMatchInfo
                examInfo.saoreList[index] = element
                this.examMatchInfo = examInfo
                this.saveExamInfoData('forfeit')
              }).catch((e) => {
                if (e === 'cancel') {
                  // TODO 确认不通过执行逻辑
                  // 换考场
                  this.deleteExamineeData = element
                  // 确认删除
                  this.confirmDelectCateInfo('forfeit')
                }
              })
            }
          }
        }
      }
      saveNetScores(this.examMatchInfo).then(res => {
        if (type === 'staging') {
          this.isChange = false
          this.addExamineeDialogVisible = true
        } else if (type === 'delete') {
          this.confirmDelectCateInfo('forfeit')
        } else if (type === 'temporary') { // 暂存，不做提示处理
          this.$forceUpdate()
          return
        } else if (type === 'forfeit') {
          this.saveExamInfoData('save')
        } else if (type === 'lock') { // 暂存，不做提示处理
        } else {
          this.$alert('保存成功', '提示', {
            confirmButtonText: '确定'
            // callback: action => {
            //   this.$refs.queryFormRef.resetFields()
            //   this.matchInfoShow = false
            // }
          })
        }
        this.getMatchInfo()
      })
    },
    // 锁定选手信息
    lockUserDialog (scope) {
      const rowData = scope.row
      console.log('rowData', rowData)
      if (rowData.score === -1) {
        this.onPassClickChange(scope, 'compute')
      } else {
        rowData.score = -1
        var examInfo = this.examMatchInfo
        examInfo.saoreList[scope.index] = rowData
        this.examMatchInfo = examInfo
        this.saveExamInfoData('temporary')
      }
    },
    // 成绩单选框点击事件(计算成绩)
    onPassClickChange (scope, type) {
      if (scope.row.score === -1 && type !== 'compute') {
        return
      }
      this.isChange = true
      console.log('scope', scope)
      const rowData = scope.row
      this.totalScore = 0
      if (rowData.round1 === 1) {
        this.totalScore += 5
      }
      if (rowData.round2 === 1) {
        this.totalScore += 8
      }
      if (rowData.round3 === 1) {
        this.totalScore += 10
      }
      if (rowData.round4 === 1) {
        this.totalScore += 12
      }
      if (rowData.round5 === 1 && this.netMatchType === '5') {
        this.totalScore += 15
      }
      if (rowData.round1 !== -1 && rowData.round2 !== -1 && rowData.round3 !== -1 && rowData.round4 !== -1 && this.netMatchType === '4') {
        rowData.score = this.totalScore
        this.examMatchInfo.saoreList[scope.index] = rowData
      } else if (rowData.round1 !== -1 && rowData.round2 !== -1 && rowData.round3 !== -1 && rowData.round4 !== -1 && this.netMatchType === '5') {
        rowData.score = this.totalScore
        this.examMatchInfo.saoreList[scope.index] = rowData
      } else {
        rowData.score = this.totalScore
      }
      this.saveExamInfoData('temporary')
    },
    // 考场成绩信息保存点击事件
    examInfoSaveClicked () {
      this.saveExamInfoData('save')
    },
    // 保存添加考生
    saveAddExamineeClick () {
      this.$refs.addExamineeFormRef.validate(async valid => {
        if (!valid) return
        this.addExamineeForm.roomId = this.queryForm.id
        batchExaminationRoom(this.addExamineeForm).then(res => {
          if (res.code === 4008) {
            var messageList = res.msg.split('***')
            this.$confirm(messageList[0] + ':' + messageList[1] + messageList[2], '提示', {
              confirmButtonText: '查看',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.addExamineeDialogVisible = false
              this.queryForm.id = messageList[3]
              this.highLightPhone = messageList[1]
              this.getMatchInfo()
            }).catch(() => {
            })
          } else {
            this.addExamineeDialogVisible = false
            this.$message.success('保存成功')
            this.getMatchInfo()
          }
        })
      })
    },
    // 关闭添加考生弹窗
    closeAddExamineeDialog () {
      this.$refs.addExamineeFormRef.resetFields()
    },
    // 删除点击事件
    showDeleteExamUserDialog (deleteData) {
      this.$confirm('是否将该考生删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteExamineeData = deleteData
        // 确认删除
        this.saveExamInfoData('delete')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 确认删除
    confirmDelectCateInfo (type) {
      removeUserFromExamRoom({
        id: this.deleteExamineeData.roomSitId
      }).then(res => {
        if (type === 'forfeit') {
          this.getGameInfo(type)
        } else {
          this.$message.success('操作成功')
          this.getMatchInfo()
        }
      })
    }
  }
}
</script>

  <style lang="less" scoped>
.span_row {
  display: flex;
  flex-direction: row;
}
.card-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.scoreClass {
  font-size: 16px;
  color: green;
}
.el-row {
  margin: 10px 0;
}
.exam_span_class {
  font-size: 14px;
}
</style>
